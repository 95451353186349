import React from "react";
import Logo from "./Files/Logo.png"
import "./Components.css"


const Header_Navbar = () => {

    return (
        <div className="textColor">
            <nav className="navbar navbar-expand-lg navbar-light">
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarTogglerDemo03"
                    aria-controls="navbarTogglerDemo03"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <a className="navbar-brand" href="/">
                    <img className={"Logo"} src={Logo} alt="Logo"></img>
                </a>
                <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                    <ul className="nav navbar-nav mr-auto mt-2 mt-lg-0">
                    <li className="nav-item">
                            <a className="nav-link" href="/">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/aboutme">About me</a>
                        </li>
                        <li className="nav-item dropdown">
                            <div className="btn-group">
                                <button className="btn btn-sm nav-link" type="button">
                                    <a className="dropdown-item" href="/blog">Blog</a>
                                </button>
                                <button type="button" className="btn dropdown-toggle dropdown-toggle-split"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                </button>
                                <ul className="dropdown-menu" id="navbar_margin">
                                    <li><a className="dropdown-item" href="/schweden">Schweden</a></li>
                                    <li><a className="dropdown-item" href="/norwegen">Norwegen</a></li>
                                    <li><a className="dropdown-item" href="/zandvoort">Zandvoort</a></li>
                                    <li><a className="dropdown-item" href="/srilanka">Sri Lanka</a></li>
                                    <li><a className="dropdown-item" href="/thailand">Thailand</a></li>
                                    <li><a className="dropdown-item" href="/split">Split</a></li>
                                    <li><a className="dropdown-item" href="/mauritius">Mauritius</a></li>
                                    <li><a className="dropdown-item" href="/belize">Belize</a></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
            <hr className="dividerLine"/>
        </div>
    );
};

export default Header_Navbar;
