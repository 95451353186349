const Timeline = () => {
    const timelineItems = [
        {
            date: '2025 Januar',
            title: 'Schweden',
            description: 'Schneelandschaft Sandsjögarden',
            link: '/schweden'
        },
        {
            date: '2024 September',
            title: 'Norwegen',
            description: 'Roadtrip via Schweden nach Norwegen',
            link: '/norwegen'
        },
        {
            date: '2024 März',
            title: 'Zandvoort',
            description: 'Amsterdam, Tulpen und Meer',
            link: '/zandvoort'
        },
        {
            date: '2024 Januar/Februar',
            title: 'Sri Lanka',
            description: 'Kultur hautnah.',
            link: '/srilanka'
        },
        {
            date: '2023 Oktober',
            title: 'Thailand',
            description: 'Stadt und Insel.',
            link: '/thailand'
        },
        {
            date: '2023 September',
            title: 'Split',
            description: 'Weekendtrip ans Meer',
            link: '/split'
        },
        {
            date: '2023 Mai',
            title: 'Mauritius und Rodrigues',
            description: 'Rundreise und Inselleben.',
            link: '/mauritius'
        },
        {
            date: '2022 Oktober ',
            title: 'Entdeckungsreise Belize',
            description: 'Dschungel, Stadt und Landschaft.',
            link: '/belize'
        }
    ];


    return (
        <div className="timeline-container my-5">
            <h2 className="text-center mb-4">Meine Reisen</h2>
            <ul className="timeline">
                {timelineItems.map((item, index) => (
                    <li key={index} className="timeline-item">
                        <span className="timeline-date">{item.date}</span>
                        <h3 className="timeline-title">{item.title}</h3>
                        <p className="timeline-description">{item.description}</p>
                        <a href={item.link} className="timeline-link">zum Blog</a>
                    </li>
                ))}
            </ul>
        </div>
    );
};
export default Timeline;