import BlogPostContainer from "./BlogPostContainer";

const Schweden = () => {
    const blogPosts = [
        {
            date: "26.01.2025",
            title: "3. Tag",
            content: (
                <>
                    Guten Abend und herzlich Willkommen zu einer neuen Folge "was ist heute passiert"
                    <br/><br/>
                    Heute sind wir in der Lodge Frühstücken gegangen. Nachdem wir die ware Kleidung montiert hatten, ging es
                    zu den Huskyzwinger. Nach der Hundeverabschiedung ging es auf einen kurzen Spaziergang am See entlang.
                    <br/><br/>
                    Um 13.30 Uhr haben wir uns dann mit Caro getroffen um unsere Schneemobil Tour zu starte. Nach der Einkleidung
                    gab es die Theoretische Einführung.
                    <br/><br/>
                    Nach zwei sehr coolen Stunden Fahrt sind wir einigermassen Müde zurück in den Hütten. Abendessen gab es
                    dieses Mal in der Lodge.
                    <br/><br/>
                    Eure Snowmobilefahrer
                </>
            ),
            images: [
                "/images/08_Schweden/3.1.jpeg",
                "/images/08_Schweden/3.2.jpeg",
                "/images/08_Schweden/3.3.jpeg",
                "/images/08_Schweden/3.4.jpeg",
                "/images/08_Schweden/3.5.jpeg",
                "/images/08_Schweden/3.6.jpeg",
            ]
        },
        {
            date: "25.01.2025",
            title: "2. Tag",
            content: (
                <>
                    Hej
                    <br/><br/>
                    Ein früher Flug später sind wir in Luleå angekommen. Nach erfolgreicher Suche nach unserem Mietwagen
                    fuhren wir los Richtung unseres Zuhauses für diese Woche. Leider ist Blattnicksele nicht ganz um die Ecke,
                    aber Entfernung ist in Schweden normal. Nach einer etwa 3 Stündigen Fahrt, haben wir einen Zwischenhalt
                    zum Einkaufen gemacht. Danach ging es nochmals ungefähr eine halbe Stunde Richtung Sandsjögarden.
                    <br/><br/>
                    Nach Einzug in der Hütte und einer kleinen Verschnaufpause haben wir uns für einen kurten Entdeckungsspaziergang
                    entschieden.
                    <br/><br/>
                    Zur Stärkung gab es Spaghetti und nach ein paar runden Spielen ging es direkt in die eigene Hütte.
                    <br/><br/>
                    Morgen gehts für uns auf ein rasantes Abenteuer. Davon erzähle ich euch morgen mehr.
                    <br/><br/>
                    Gute Nacht und bis morgen
                    <br/><br/>
                    Die Schneespaziererin
                </>
            ),
            images: [
                "/images/08_Schweden/2.1.jpeg",
                "/images/08_Schweden/2.2.jpeg",
                "/images/08_Schweden/2.3.jpeg",
                "/images/08_Schweden/2.4.jpeg",
                "/images/08_Schweden/2.5.jpeg",
                "/images/08_Schweden/2.6.jpeg",
            ]
        },
        {
            date: "24.01.2025",
            title: "1. Tag",
            content: (
                <>
                    Hallihallo zu einem neuen Tagebuch... diesen Mal für SCHWEDEN
                    <br/><br/>
                    Freitag 24.01.2025
                    <br/>
                    05:00 Uhr: Abfahrt nach Grenchen
                    <br/>
                    05:38 Uhr: Bus nach Grenchen Bahnhof
                    <br/>
                    Zug: Grenchen nach Zürich Flughafen
                    <br/>
                    07:35 Uhr: Check-in 3 und Security-Check
                    <br/>
                    5x UNO
                    <br/>
                    laufen zum Gate
                    <br/>
                    Boarding
                    <br/><br/>
                    Soviel zu unserer Reise nach Stockholm. Nach Ankunft im Hotel direkt am Flughafen ging es mit dem Zug
                    Richtung Stockholm Zentrum. Erster Stopp "Hardrock Cafe", Checklistenpunkt T-Shirt abgeschlossen.
                    Nach einem Spaziergang weiter Richtung Stadtkern und Besuch einiger Shops gab es ein feines Znacht.
                    <br/><br/>
                    Morgen geht es wieder Früh los, deshalb heute bei Zeiten ins Bett.
                    <br/><br/>
                    Tschüssi und ich melde mich morgen wieder, nach unserer Ankunft in Sandsjögarden.
                    <br/><br/>
                    Eure AufEinemNeuenAbenteurer
                </>
            ),
            images: [
                "/images/08_Schweden/1.jpeg",
                "/images/08_Schweden/2.jpeg",
                "/images/08_Schweden/3.jpeg",
                "/images/08_Schweden/4.jpeg",
                "/images/08_Schweden/5.jpeg",
                "/images/08_Schweden/6.jpeg",
            ]
        },
    ];
    return (
        <div className="container my-5">
            <div>
                <h2 className="Blog_Title">Schweden</h2>
            </div>
            {blogPosts.map((post, index) => (
                <BlogPostContainer
                    index={index}
                    title={post.title}
                    date={post.date}
                    content={post.content}
                    images={post.images}
                />
            ))}
        </div>
    );
}
export default Schweden;